import React from 'react';

/*IMPORT MEDIA */
import logo from '../../images/logos/logo-app.png'

function Footer() {
    return (

        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <a class="navbar-brand" href="index.html">İsteBiKurye</a>
                                <small></small>                        </div>
                            <p>
                                Artık işinizi kolaylaştıracak şehir içi kargo gönderim uygulama her zaman yanınızda! </p>
                            <p>      Anında Bi Kuriye ile istediğiniz istanbul konumundan bir yerder eşyalarınızı hızlı bir şekilde gönderim sağlayabilirsiniz...
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <h3>İletişim </h3>
                            </div>

                            <ul class="footer-links">
                                <li><a href="mailto:#">destek@istebikurye.com/</a></li>
                                <li><a href="https://istebikurye.com.tr/">www.istebikurye.com.tr</a></li>
                                <li>ikiteli m.akif mah barboros sokak no7 d 7 küçükcekmece/Istanbul</li>
                                <li>+90 546 140 38 44</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                        <div class="widget clearfix">
                            <div class="widget-title">
                                <h3>Sosyal </h3>
                            </div>
                            <ul class="footer-links">
                                <li><a href="#"><i class="fa fa-facebook"></i> Facebook</a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i> Twitter</a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i> Pinterest</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="footer-distributed widget clearfix">
                            <div class="widget-title">
                                <h3>Abone Ol</h3>
                                <p>Her güncellememizi ve haberimizi kaçırmayın!</p>
                            </div>
                            <div class="footer-right">
                                <form method="get" action="#">
                                    <input placeholder="Haber Bültenimize Kaydol.." name="search" />
                                    <i class="fa fa-envelope-o"></i>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="copyrights">
                <div class="container">
                    <div class="footer-distributed">
                        <div class="footer-left">
                            <p class="footer-company-name">Bütün hakları saklıdır &copy; 2024 Istanbul <a href="https://istebikurye.com.tr/">İsteBiKurye</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer